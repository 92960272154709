export default {
    title: 'UX: Discovery',
    desc: 'UX Discovery involves the initial phase of user research, where designers seek to understand the users and their needs. This phase is crucial for developing user-centered design solutions that meet the needs and expectations of the target audience.',
    1: {
        title: 'Card Sorting',
        desc: 'Card sorting is a research method used to understand how people group information and identify patterns in the way users organize information. It is a technique used to create information architecture by allowing users to organize content into categories or groups that make sense to them.',
        links: [
            {
                title: 'More About Card Sorting (NN/g)',
                url: 'https://www.nngroup.com/articles/card-sorting-definition/',
            },
            {
                title: 'Card Sorting: A Definitive Guide (Optimal Workshop)',
                url: 'https://www.optimalworkshop.com/learn/guides/card-sorting'
            }
        ]
    },
    2: {
        title: 'Cognitive Walkthrough',
        desc: 'A Cognitive Walkthrough is a usability inspection method in which evaluators walk through a series of tasks and try to understand how a user might think and interact with the system. This method helps in identifying potential problems that users may encounter when using the system.',
        links: [
            {
                title: 'Cognitive Walkthroughs',
                url: 'https://insidegovuk.blog.gov.uk/2016/04/07/how-cognitive-walkthroughs-can-help-with-content-design/',
            },
            {
                title: 'Cognitive Walkthrough: Usability Evaluation Method (Interaction Design Foundation)',
                url: 'https://www.interaction-design.org/literature/article/how-to-conduct-a-cognitive-walkthrough'
            }
        ]
    },
    3: {
        title: 'Competitive Analysis',
        desc: 'Competitive analysis is the process of identifying the strengths and weaknesses of competitors within a market to inform the design of a product or service. It is an essential part of the discovery phase of UX design that helps designers identify opportunities and challenges in the market.',
        links: [
            {
                title: 'Things To Know: Competitive Analysis',
                url: 'https://uxplanet.org/top-things-to-know-about-ux-competitive-analysis-d91689fd8b36',
            },
            {
                title: 'A Comprehensive Guide to Conducting a Competitive Analysis (HubSpot)',
                url: 'https://blog.hubspot.com/marketing/competitive-analysis-kit'
            }
        ]
    },
    4: {
        title: 'Ethnographic Study',
        desc: 'Ethnographic studies are a research method used to understand the social and cultural practices of a group or community. In UX design, this method is used to gain a deeper understanding of the users and their behaviors, needs, and expectations.',
        links: [
            {
                title: 'What is Ethnographic Research?',
                url: 'https://uxdesign.cc/the-power-of-ethnographic-research-d7e3de947221',
            },
            {
                title: 'Ethnographic Research in UX Design (Interaction Design Foundation)',
                url: 'https://www.interaction-design.org/literature/topics/ethnographic-research'
            }
        ]
    },
    5: {
        title: 'Focus Groups',
        desc: 'Focus groups are a research method used to gather qualitative data from a group of people about their opinions, perceptions, and attitudes towards a product or service. They are often used in the early stages of the design process to understand user needs and preferences.',
        links: [
            {
                title: 'How to Conduct Effective Focus Groups',
                url: 'https://www.nngroup.com/articles/focus-groups/',
            },
            {
                title: 'The Advantages and Disadvantages of Focus Groups',
                url: 'https://think.design/user-design-research/focus-groups/',
            }
        ]
    },
    6: {
        title: 'Heuristic Evaluation',
        desc: 'Heuristic evaluation is a usability inspection method in which a usability expert evaluates a user interface based on a set of usability principles, or heuristics. This method is useful for identifying usability issues early in the design process.',
        links: [
            {
                title: '10 Usability Heuristics for User Interface Design',
                url: 'https://www.nngroup.com/articles/ten-usability-heuristics/',
            },
            {
                title: 'Heuristic Evaluation - Usability.gov',
                url: 'https://www.usability.gov/how-to-and-tools/methods/heuristic-evaluation.html',
            }
        ]
    },
    7: {
        title: 'SWOT Analysis',
        desc: 'SWOT analysis is a strategic planning tool used to identify the strengths, weaknesses, opportunities, and threats of a product or service. It helps designers to evaluate the potential of their designs and identify areas that need improvement.',
        links: [
            {
                title: 'What is a SWOT Analysis and How to Conduct One?',
                url: 'https://uxstrategykit.merck.design/methods/swot-analysis',
            },
            {
                title: 'SWOT Analysis: Definition and Examples',
                url: 'https://www.kickassux.com/ux-library/swot-analysis',
            }
        ]
    },
    8: {
        title: 'Affinity Map',
        desc: 'An affinity map is a visual tool used to organize and group large amounts of data and ideas into meaningful categories. This method is useful for analyzing user feedback, identifying patterns, and generating insights that can inform the design process.',
        links: [
            {
                title: 'Affinity Diagrams: How to Cluster Your Ideas and Reveal Insights',
                url: 'https://www.interaction-design.org/literature/article/affinity-diagrams-learn-how-to-cluster-and-bundle-ideas-and-facts',
            },
            {
                title: 'How to Use Affinity Mapping to Organize Research',
                url: 'https://www.usertesting.com/blog/affinity-mapping',
            }
        ]
    },
    9: {
        title: 'Journey Map',
        desc: 'A journey map is a visual representation of a user’s journey through a product or service. It shows the user’s actions, thoughts, and feelings as they interact with the product or service.',
        links: [
            {
                title: 'How to Create a Journey Map (Step-by-Step)',
                url: 'https://uxmastery.com/how-to-create-a-customer-journey-map/',
            },
            {
                title: 'The Beginner’s Guide to Customer Journey Mapping',
                url: 'https://medium.com/choice-hacking/the-beginners-guide-to-creating-customer-journey-maps-69b62f6a41ef',
            }
        ]
    },
    10: {
        title: 'Empathy Map',
        desc: 'An empathy map is a tool that helps UX designers understand their users’ needs and experiences. It is used to gain insights into what users think, feel, see, hear, and do.',
        links: [
            {
                title: 'How to Create an Empathy Map',
                url: 'https://www.uxbooth.com/articles/empathy-mapping-a-guide-to-getting-inside-a-users-head/',
            },
            {
                title: 'Empathy Mapping: How to Walk in Someone Else’s Shoes',
                url: 'https://www.nngroup.com/articles/empathy-mapping/',
            }
        ]
    },
    11: {
        title: 'Consent Forms',
        desc: 'A consent form is a document that explains the research study and what participation in the study involves. It also explains the risks and benefits of participating in the study, and it asks for the participant’s consent to participate.',
        links: [
            {
                title: 'Creating Informed Consent Forms',
                url: 'https://www.userinterviews.com/blog/how-to-write-research-participant-consent-forms',
            },
            {
                title: 'Informed Consent Forms: A Practical Guide for UX Researchers',
                url: 'hhttps://www.nngroup.com/articles/informed-consent/',
            }
        ]
    },
    12: {
        title: 'Sessions Guides',
        desc: 'A session guide is a document that outlines the goals and objectives of a research study, as well as the methodology and techniques that will be used to achieve those goals. It provides a roadmap for conducting the study and ensures that all team members are aligned on the research objectives.',
        links: [
            {
                title: 'How to Create a UX Research Plan',
                url: 'https://maze.co/guides/ux-research/plan/',
            },
            {
                title: 'Creating a User Research Plan: A Step-by-Step Guide',
                url: 'https://www.userzoom.com/ux-research/plan-strategy/',
            }
        ]
    },
    13: {
        title: 'User Interviews',
        desc: 'User interviews are a valuable research method for gathering insights about user behavior, preferences, and needs. By conducting one-on-one conversations with users, you can gather qualitative data that can inform product design decisions.',
        links: [
            {
                title: 'Comprehensive guide to conducting user interviews in 2025',
                url: 'https://dovetail.com/ux/user-interviews/',
            },
            {
                title: '10 Tips for Conducting Effective User Interviews',
                url: 'https://www.forbes.com/sites/forbestechcouncil/2022/05/06/10-pro-tips-to-master-user-interviews/',
            },
        ]
    },
    14: {
        title: 'Define Goals',
        desc: 'Defining clear and specific goals for your design project can help you stay focused on what you are trying to achieve. Goals provide direction for your design decisions and help you measure the success of your project.',
        links: [
            {
                title: 'How to Define Your Design Goals',
                url: 'https://www.uxpin.com/studio/blog/6-goals-for-product-design-teams-sd/',
            },
            {
                title: 'Designing Effective Design Goals',
                url: 'https://uxdesign.cc/designing-effective-design-goals-3a6f71b6c9a6',
            },
        ]
    },
    15: {
        title: 'Map Domains',
        desc: 'Mapping domains is a process of identifying the different areas of your project or system and how they relate to each other. Domain mapping can help you understand the scope of your project and identify potential design problems before they occur.',
        links: [
            {
                title: 'Context mapping in Domain Driven Design',
                url: 'https://medium.com/ingeniouslysimple/context-mapping-in-domain-driven-design-9063465d2eb8',
            }
        ]
    },
    16: {
        title: 'User Personas',
        desc: 'User personas are fictional representations of your ideal users based on research and data. Personas help you understand your users’ goals, behaviors, motivations, and pain points, so you can design products that meet their needs.',
        links: [
            {
                title: 'The Beginner’s Guide to User Personas',
                url: 'https://chetanabhishek.medium.com/a-beginners-guide-to-user-personas-why-they-are-critical-and-how-to-create-them-7ee82235ea08',
            },
            {
                title: 'Creating User Personas',
                url: 'https://uxmentor.me/personas-the-beginners-guide/',
            },
        ]
    },
    17: {
        title: 'User Stories',
        desc: 'User stories are short, simple descriptions of a feature or function of a product, written from the perspective of the user. They help to clarify and communicate what the user wants and needs, and can be used as a tool for prioritizing product development.',
        links: [
            {
                title: 'Introduction to User Stories',
                url: 'https://www.atlassian.com/agile/project-management/user-stories',
            },
            {
                title: 'Writing User Stories',
                url: 'https://uxdesign.cc/how-to-write-meaningful-user-stories-3bcb1d51ff',
            }
        ]
    },
    18: {
        title: 'Define Scope & MVP',
        desc: 'Defining the scope and MVP (minimum viable product) is an important step in the product development process. It involves setting clear goals and objectives for the product, identifying the features and functions that will be included in the initial release, and determining the timeline and resources needed to complete the project.',
        links: [
            {
                title: 'Defining Scope and MVP',
                url: 'https://www.productplan.com/glossary/minimum-viable-product/',
            },
            {
                title: 'How to Define Scope and MVP',
                url: 'https://ultracommerce.co/blog/defining-a-minimum-viable-product-scope',
            }
        ]
    }
}